<template>
  <main>
    <div class="content_wrapper">
      <div class="annex">
        <div class="content finish">
          <img src="../assets/images/logo.jpg" /><br />
          L'opération BLUE PURE GROHE est à présent terminée !<br />

          <p>
            Pour toute demande concernant l'offre et une participation en cours,
            rendez-vous dans la rubrique <router-link  style="color:#162c4a" to="/contact">contact</router-link>. <br /><br />
            Á bientôt pour une prochaine opération !
            <br /><br />
            <span>L'équipe GROHE</span>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "finish",
};
</script>
